<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/images/logo/dompetkilat.png')"
              max-width="190px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="mb-2">
            Please sign-in to your account first
          </p>
        </v-card-text>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="creds.username"
              outlined
              autofocus
              color="primary"
              label="Username"
              placeholder="username"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="creds.password"
              outlined
              color="primary"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              hide-details
              :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-6 white--text text-none"
              @click="login"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Don't have an account?
          </span>
          <span class="me-2">
            Please contact your administrator
          </span>
        </v-card-text>
      </v-card>
    </div>

    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-light.png`)"
    />

    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>

    <popup-error
      :dialog="showErrDialog"
      :closeDialog="closeErrDialog"
      :errorMessage="'Mohon periksa username dan password'"
    />
  </div>
</template>

<script>
import store from "../store/index.js";
import PopupError from "../components/Popup/PopupError.vue";

export default {
  name: "Login",

  components: { PopupError },

  methods: {
    async login() {
      try {
        await store.dispatch("auth/login", this.creds);
        if (store.state.auth.status === "error") {
          this.showErrDialog = true;
        }
      } catch (err) {
        this.showErrDialog = true;
      }
    },

    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
  },

  data: () => ({
    creds: {
      username: "",
      password: "",
    },
    showErrDialog: false,
    isPasswordVisible: false,
  }),
};
</script>

<style lang="scss">
@import "~@/assets/stylesheet/auth.scss";
</style>
